import React, { Component } from 'react'
import axios from "axios";
import Loader from "../shared/Loader";
import Swal from "sweetalert2";
import helpers from "../shared/layouts/helpers";
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    Form,
    InputGroup,
    ListGroup,
    Media,
    Modal,
    Nav,
    Row,
    Table
} from "react-bootstrap";
import * as numeral from 'numeral';
import '../vendor/libs/react-stepzilla/react-stepzilla.scss'
import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {withTranslation} from "react-i18next";
import htmlParse from "html-react-parser";
import TextModal from "./Modals/TextModal";
import {Event, ModalCheckout} from "@bambora/checkout-sdk-web";
import {Label} from "recharts";
import {Link} from "react-router-dom";
import AuthService from "../services/auth-service";

class Claim extends Component {
    constructor(props) {
        super(props);
        props.setTitle(this.props.t('Claim'));
        helpers.setCollapsed(true)
        helpers.setPosition(true, true);
        this.state = {
            loading: true,
            claim: {},
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        axios.get(helpers.getAPI() + '/partner/api/device/shop/customer/get/claim/'+this.props.match.params.guid).then(res => {
            if (res.data.userArray) {
                localStorage.setItem("mentechShopDetails", JSON.stringify(res.data.userArray))
            }
            this.setState({claim: res.data.claim, declineOptions: res.data.declineOptions, paymentLink: res.data.paymentLink, loading: false}, () => {

            })
        })
    }

    handleSubmit(event) {

    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    decline() {
        this.setState({loading: true}, () => {
            axios.post(helpers.getAPI() + '/partner/api/device/shop/customer/decline/buyOff/' + this.props.match.params.guid, JSON.stringify(this.state)).then(res => {
                console.log(res.data);
                this.setState({
                    loading: false,
                    claim: res.data,
                    decline: null,
                    declineReason: null,
                    reason: null
                });
            })
        })
    }

    printInvoice() {

    }

    printShippingLabel() {
        this.setState({loading: true}, () => {
            axios.get(helpers.getAPI() + '/partner/api/device/shop/customer/claim/return/shippingLabel/' + this.props.match.params.guid).then((response) => {
                let claim = this.state.claim;
                claim.tracking = response.data.tracking;
                this.setState({loading: false, claim: claim}, () => {
                    window.open(response.data.label);
                })
            }).catch((error) => {
                this.setState({loading: false}, () => {

                    Swal.fire({
                        title: 'Something went wrong..',
                        text: 'Could not print shipping label. Please try again',
                        icon: 'error',
                        confirmButtonColor: "#26B4FF"
                    });
                })
            });
        })
    }

    accept() {
        window.location = this.state.paymentLink;
    }

    render() {
        return (
            <div>
                {this.state.loading && <Loader/>}
                <h4 className="font-weight-bold py-3 mb-4">
                    {this.props.t('Claim')}
                </h4>

                <Row>
                    <Col md={0} xl={3}></Col>
                    <Col md={12} xl={6} className={"pb-4"}>
                        <Card>
                            <Card.Body>
                                <h6 className="text-muted small font-weight-semibold">
                                    <i className={"ion ion-ios-cart"}></i> {this.props.t("Equipment")}
                                </h6>
                                <Table bordered responsive style={{ minWidth: '550px' }}>
                                    <tbody>
                                    {this.state.claim.device && <tr key={this.state.claim.device.id}>
                                        <td className="p-4">
                                            <Media className="align-items-center">
                                                {this.state.claim.device.picture && <img src={this.state.claim.device.picture} className="d-block ui-w-40 ui-bordered mr-4" alt={this.state.claim.device.model} />}
                                                <Media.Body>
                                                    <p className="d-block text-body">
                                                        <strong>{this.state.claim.device.type} {this.state.claim.device.model}</strong>
                                                        <br/>
                                                        <small>{this.state.claim.device.description}</small>
                                                    </p>
                                                </Media.Body>
                                            </Media>
                                        </td>
                                    </tr>}
                                    {this.state.claim.device2 && <tr key={this.state.claim.device2.id}>
                                        <td className="p-4">
                                            <Media className="align-items-center">
                                                
                                                {this.state.claim.device2.picture && <img src={this.state.claim.device2.picture} className="d-block ui-w-40 ui-bordered mr-4" alt={this.state.claim.device2.model} />}
                                                <Media.Body>
                                                    <p className="d-block text-body">
                                                        <strong>{this.state.claim.device2.type} {this.state.claim.device2.model}</strong>
                                                        <br/>
                                                        <small>{this.state.claim.device2.description}</small>
                                                    </p>
                                                </Media.Body>
                                            </Media>
                                        </td>
                                    </tr>}
                                    {this.state.claim.accessories && Object.keys(this.state.claim.accessories).map( (key, id) => ( <tr key={id}>
                                            <td className="p-4">
                                                <Media className="align-items-center">
                                                    <Media.Body>
                                                        <p className="d-block text-body">
                                                            <strong>{(this.state.claim.accessories[key])}</strong>
                                                        </p>
                                                    </Media.Body>
                                                </Media>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>

                            {["5","6"].includes(this.state.claim.type_id) && <Card.Body>
                                {this.state.claim.type_id == 5 && <>
                                    {this.state.claim.buyOffAccepted == -1 && (this.state.claim.device && this.state.claim.device.rightOfComplaint) && <p className={"text-center text-big text-muted"}>{this.props.t('return.option.declined.details', {company: helpers.getUser().company})}</p>}
                                    {!this.state.claim.device.rightOfComplaint && <p className={"text-center text-big text-muted"}>{this.props.t('return.option.return.details', {company: helpers.getUser().company})}</p>}
                                    {!this.state.claim.buyOffAccepted && this.state.claim.device.rightOfComplaint && <p className={"text-center text-big text-muted"}>{this.props.t('return.option.details', {company: helpers.getUser().company})}</p>}
                                    {this.state.claim.buyOffAccepted == 1 && <p className={"text-center text-big text-muted"}>{this.props.t('return.option.accepted.details', {company: helpers.getUser().company})}</p>}
                                </>}
                                {this.state.claim.type_id == 6 && <>
                                    {this.state.claim.buyOffAccepted == -1 && (this.state.claim.device && this.state.claim.device.rightOfComplaint) && <p className={"text-center text-big text-muted"}>{this.props.t('ordinary.expiration.declined.details', {company: helpers.getUser().company})}</p>}
                                    {!this.state.claim.device.rightOfComplaint && <p className={"text-center text-big text-muted"}>{this.props.t('ordinary.expiration.return.details', {company: helpers.getUser().company})}</p>}
                                    {!this.state.claim.buyOffAccepted && this.state.claim.device.rightOfComplaint && <p className={"text-center text-big text-muted"}>{this.props.t('ordinary.expiration.details', {company: helpers.getUser().company})}</p>}
                                    {this.state.claim.buyOffAccepted == 1 && <p className={"text-center text-big text-muted"}>{this.props.t('ordinary.expiration.accepted.details', {company: helpers.getUser().company})}</p>}
                                </>}

                                {!this.state.claim.recieved && this.state.claim.buyOffAccepted == -1 && !this.state.claim.completedDate && <div className={"text-center"}>
                                    <Badge variant={"warning"}>{this.props.t('Return the equipment before', {date: this.state.claim.returnBefore})}</Badge>
                                </div>}

                                <hr className="mt-5 mb-4"/>
                                <Row>
                                    <Col md={6}>
                                        <div className={"mb-3"}>
                                            <div className="text-muted small">{this.props.t("Reference")}</div>
                                            <Badge variant={"primary"}>#{this.state.claim.id}</Badge>
                                        </div>
                                        <div className={"mb-3"}>
                                            <div className="text-muted small">{this.props.t("Status")}</div>
                                            <Badge variant={"outline-primary"}>{this.props.t(this.state.claim.status)}</Badge>
                                        </div>
                                        {this.state.claim.buyOffAccepted == -1 && <div className={"mb-3"}>
                                            <div className="text-muted small">{this.props.t("Received")}</div>
                                            {this.state.claim.received ? this.props.t('Yes, received on ', {'date': this.state.claim.receivedDate}) : this.props.t('No')}
                                        </div>}
                                        {this.state.claim.tracking && <div className={"mb-3"}>
                                            <div className="text-muted small">{this.props.t("Tracking")}</div>
                                            {this.state.claim.tracking}
                                        </div>}
                                    </Col>

                                    {(!this.state.claim.received && !this.state.claim.tracking && this.state.claim.priceMatch > 0 && !this.state.claim.completedDate) && (this.state.claim.device && this.state.claim.device.rightOfComplaint) && <Col md={6}>
                                        <div className="text-right">
                                            <div className="text-muted">{this.props.t("Total incl. vat")} </div>
                                            <div className="text-large">
                                                {!this.state.claim.newPriceMatch && <strong>{this.props.currency.format(this.state.claim.priceMatch ? this.state.claim.priceMatch : "0")}</strong>}
                                                {this.state.claim.newPriceMatch && <del>{this.props.currency.format(this.state.claim.priceMatch ? this.state.claim.priceMatch : "0")}</del>}
                                            </div>
                                        </div>
                                        {this.state.claim.newPriceMatch && <div className="text-right mt-2">
                                            <div className="text-muted">{this.props.t("We have calculated a new price for you")} </div>
                                            <div className="text-large display-1">
                                                <h1 className={"text-success"}>{this.props.currency.format(this.state.claim.newPriceMatch ? this.state.claim.newPriceMatch : "0")}</h1>
                                            </div>
                                        </div>}
                                    </Col>}
                                </Row>

                            </Card.Body>}

                            {["5","6"].includes(this.state.claim.type_id) && this.state.claim.buyOffAccepted == 1 && <Card.Footer className={"text-center p-4"}>
                                <Link to={"/claim/print/"+this.props.match.params.guid} variant={"info"} className={"btn btn-info text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Print invoice")}</Link>
                            </Card.Footer>}

                            {["5","6"].includes(this.state.claim.type_id) && this.state.claim.buyOffAccepted == -1 && !this.state.claim.received && !this.state.claim.completedDate && <Card.Footer className={"text-center p-4"}>
                                <Button onClick={() => this.printShippingLabel()} variant={"info"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Print shipping label")}</Button>
                                {!this.state.claim.tracking && (this.state.claim.device && this.state.claim.device.rightOfComplaint) && <Button onClick={() => this.accept()} variant={"success"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Accept and keep equipment")}</Button>}
                            </Card.Footer>}

                            {["5","6"].includes(this.state.claim.type_id) && !this.state.claim.buyOffAccepted && !this.state.decline && this.state.claim.priceMatch > 0 && !this.state.claim.completedDate && this.state.claim.device.rightOfComplaint && <Card.Footer className={"text-center p-4"}>
                                <Button onClick={() => this.setState({decline: true})} variant={"danger"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Decline and return equipment")}</Button>
                                <Button onClick={() => this.accept()} variant={"success"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Accept and keep equipment")}</Button>
                            </Card.Footer>}

                            {["5","6"].includes(this.state.claim.type_id) && !this.state.claim.buyOffAccepted && !this.state.decline && this.state.claim.priceMatch > 0 && !this.state.claim.completedDate && !this.state.claim.device.rightOfComplaint && <Card.Footer className={"text-center p-4"}>
                                <Button onClick={() => this.decline()} variant={"success"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Return equipment")}</Button>
                            </Card.Footer>}

                            {["5","6"].includes(this.state.claim.type_id) && this.state.decline && <Card.Footer className={"text-center p-4"}>
                                <Form.Group>
                                    <Form.Label>{this.props.t('Reason')}</Form.Label>
                                    <Form.Control as={"select"} name={"reason"} defaultValue={""} onChange={this.handleInputChange} value={this.state.reason}>
                                        <option disabled value={""}>{this.props.t('Select an option')}</option>
                                        {Object.keys(this.state.declineOptions).map( (key, id) => ( <option key={key} value={key}>
                                            {this.props.t(this.state.declineOptions[key])}
                                        </option>))}
                                    </Form.Control>
                                </Form.Group>

                                {this.state.reason == 3 &&
                                <Form.Group>
                                    <Form.Label>{this.props.t('Please describe the reason')}</Form.Label>
                                    <Form.Control required as={"textarea"} name={"reasonDescription"} value={this.state.reasonDescription} onChange={this.handleInputChange} rows={3}></Form.Control>
                                </Form.Group>}

                                <Form.Group className={"text-left"}>
                                    <strong>{this.props.t('Please remember the following before you return your equipment')}</strong>
                                    <ul>
                                        <li>{this.props.t('Reset and delete all data from your equipment.')}</li>
                                        <li>{this.props.t('Release the equipment from the DEP.')}</li>
                                        <small>({this.props.t('Ask your IT department if in doubt. There will be additional fee of 600,- if not removed.')})</small>
                                        <li>{this.props.t('Remove your Apple ID / Google Account from the equipment.')}</li>
                                        <li>{this.props.t('Any accessories must also be returned')}</li>
                                        <li>{this.props.t('If you have kept the original packaging for the equipment, it must also be returned.')}</li>
                                    </ul>
                                    <Form.Check type="checkbox" size={"lg"} name="accept" custom checked={this.state.accept} onChange={this.handleInputChange} label={this.props.t("I confirm that equipment has been reset, and is free from Apple-ID, Google account etc.")} id={"accept"} className="m-0 "/>
                                </Form.Group>

                                <Button disabled={!this.state.reason || (this.state.reason == 3 && !this.state.reasonDescription) || !this.state.accept} onClick={() => this.decline()} variant={"danger"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Decline and return equipment")}</Button>
                                <Button onClick={() => this.accept()} variant={"success"} className={"text-expanded text-uppercase mx-2 sw-btn-finish"}>{this.props.t("Accept and keep equipment")}</Button>

                            </Card.Footer>}
                        </Card>
                    </Col>
                </Row>

          </div>
        )
    }
}

export default withTranslation()(Claim);
