import React, { Component } from 'react'
import {Form, Button, Row, Col, Card, Alert} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import {withTranslation} from "react-i18next";
import AuthService from "../services/auth-service";
import {titleTemplate} from "../routes";
import {ShaderGradient, ShaderGradientCanvas} from "shadergradient";
import * as fiber from "@react-three/fiber";
import * as drei from "@react-three/drei";
import * as reactSpring from "@react-spring/three";

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        document.title = titleTemplate.replace('%s', this.props.t("Create password"))
        document.body.style.background = "linear-gradient(to right, #243449, #52768f)";
        this.state = {
            loading: false,
            email: "",
            password: "",
        };

        if (AuthService.getCurrentUser()) {
            window.location = "/";
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    passwordsValid()
    {
        return this.state.password.length >= 6 && this.state.password === this.state.passwordConfirm;
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        if (this.state.user) {
            axios.post(helpers.getAPI(this.props.match.params.country.toUpperCase()) + '/device/shop/anonymous/create/password', JSON.stringify({guid: this.props.match.params.guid, email: this.state.email, password: this.state.password})).then(res => {
                this.setState({loading: false}, () => {
                  Swal.fire({
                      title: 'Success',
                      text: this.props.t('Your password has been created. You can now login.'),
                      icon: 'success',
                      confirmButtonColor: "#26B4FF"
                  }).then(() => {
                      window.location="/?email=" + this.state.user.username;
                  })
                })
            }).catch(error => {
                this.setState({loading: false});
                Swal.fire({
                    title: 'Something went wrong..',
                    text: helpers.parseError(error),
                    icon: 'error',
                    confirmButtonColor: "#26B4FF"
                });
            })
        } else {
            axios.post(helpers.getAPI(this.props.match.params.country.toUpperCase()) + '/device/shop/anonymous/verify/identity/' + this.props.match.params.guid, JSON.stringify({email: this.state.email})).then(res => {
                this.setState({loading: false, user: res.data})
            }).catch(error => {
                this.setState({loading: false});
                Swal.fire({
                    title: 'Something went wrong..',
                    text: helpers.parseError(error),
                    icon: 'error',
                    confirmButtonColor: "#26B4FF"
                });
            })
        }
    };

    componentDidMount() {
        /*
        axios.get(helpers.getAPI(this.props.match.params.country.toUpperCase()) + '/device/shop/anonymous/register/get/details/' + this.props.match.params.guid).then(res => {
            this.setState({
                loading: false,
                departments: this.props.match.params.department ? res.data.departments.filter(department => department.id === parseInt(this.props.match.params.department)) : res.data.departments,
                countryCodes: res.data.countryCodes,
                manager: res.data.manager,
                managerLogo: res.data.managerLogo,
                logo: res.data.logo,
            })
        }).catch(error => {
            this.setState({loading: false});
            Swal.fire(
                'Something went wrong..',
                helpers.parseError(error),
                'error'
            );
        })
         */
    }

    render() {
        return (
            <>
                {helpers.getSystem() == "moduu" && <ShaderGradientCanvas
                    importedfiber={{ ...fiber, ...drei, ...reactSpring }}
                    style={{
                        position: 'fixed',
                        height: "100%"
                    }}
                >
                    <ShaderGradient

                        control={"query"}
                        urlString={"https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=0.6&cAzimuthAngle=0&cDistance=3.1&cPolarAngle=90&cameraZoom=1&color1=%233a3a38&color2=%2359bfde&color3=%2300394e&destination=onCanvas&embedMode=off&envPreset=city&format=gif&fov=30&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=2&positionX=0&positionY=-2.1&positionZ=-0.8&range=disabled&rangeEnd=31.4&rangeStart=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=90&shader=defaults&toggleAxis=false&type=waterPlane&uAmplitude=0&uDensity=1.1&uFrequency=5.5&uSpeed=0.2&uStrength=2&uTime=0&wireframe=false"
                        }/>
                </ShaderGradientCanvas>}
            <div className="authentication-wrapper authentication-1 px-4">
                {this.state.loading && <Loader/>}

                {!AuthService.getCurrentUser() && <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '20%' }}>
                                <img className={"mb-2"} style={{width: '250px'}} src={this.state.managerLogo ? this.state.managerLogo : (process.env.PUBLIC_URL + '/img/mentech-hvidblaa.svg')}/>
                                <br/>
                                {helpers.getSystem() == "Mentech" && <small style={{ letterSpacing: '0.15em' }} as={"h6"} className={"text-white mt-4"}><strong>SHOP</strong></small>}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-4">
                            <Card>
                                {this.state.logo && <Card.Header>
                                    <img className={"mb-2"} style={{maxHeight: '35px'}} src={this.state.logo}/>
                                </Card.Header>}
                                <Card.Body>
                                    {!this.state.user && <>
                                        <h6>{this.props.t('Create password')}</h6>
                                        <hr/>
                                    </>}
                                    <Form onSubmit={this.handleSubmit}>
                                        {this.state.user && <>

                                            <Card className={"mb-3"}>
                                                <Card.Body>
                                                    <div id="sweetAlertContainer">
                                                        <div className="swal2-icon swal2-success swal2-icon-show mb-5" style={{display: 'flex'}}><div className="swal2-success-circular-line-left" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                            <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                                                            <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                            <div className="swal2-success-circular-line-right" style={{backgroundColor: "rgb(255, 255, 255)"}}></div>
                                                        </div>
                                                        <h2>{this.props.t('Thank you!', {'name': this.state.user.firstName})}</h2>
                                                        <p>{this.props.t('Your email is confirmed, and you can now create your password.')}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                            <Form.Group>
                                                <Form.Label>{this.props.t("Password")}</Form.Label>
                                                <Form.Control type={"password"} name="password" value={this.state.password} required onChange={this.handleInputChange}/>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>{this.props.t("Confirm password")}</Form.Label>
                                                <Form.Control type={"password"} name="passwordConfirm" value={this.state.passwordConfirm} required onChange={this.handleInputChange}/>
                                                {!this.passwordsValid() && this.state.password.length > 0 && <span className={"text-danger"}>{this.state.password.length < 6 ? this.props.t("Password must be a minimum of 6 characters") : this.props.t("Passwords do not match") }</span>}
                                            </Form.Group>

                                            <div className="d-flex justify-content-between align-items-center m-0">
                                                <Button type={"submit"} variant="primary">{this.props.t("Create password")}</Button>
                                            </div>
                                        </>}
                                        {!this.state.user && <>
                                            <Form.Group>
                                                <Form.Label>{this.props.t("Confirm your email")}</Form.Label>
                                                <Form.Control type={"email"} name="email" value={this.state.email} required onChange={this.handleInputChange}/>
                                            </Form.Group>

                                            <div className="d-flex justify-content-between align-items-center m-0">
                                                <Button type={"submit"} variant="primary">{this.props.t("Next")}</Button>
                                            </div>
                                        </>}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    {this.state.managerLogo && <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                {helpers.getSystem() == "Mentech" && <small as={"h6"} style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>POWERED BY</strong></small>}
                                <br/>
                                <img style={{width: '200px'}} src={process.env.PUBLIC_URL +helpers.getLogo()}/>
                            </div>
                        </div>
                    </div>}
                </div>}
            </div>
            </>
        )
    }
}

export default withTranslation()(CreatePassword);
