import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import {withTranslation} from "react-i18next";
import AuthService from "../services/auth-service";
import {titleTemplate} from "../routes";
import {ShaderGradient, ShaderGradientCanvas} from "shadergradient";
import * as fiber from "@react-three/fiber";
import * as drei from "@react-three/drei";
import * as reactSpring from "@react-spring/three";

class Register extends Component {
    constructor(props) {
        super(props);
        document.title = titleTemplate.replace('%s', this.props.t("Create your account"))
        document.body.style.background = "linear-gradient(to right, #243449, #52768f)";

        this.state = {
            loading: true,
        };

        if (AuthService.getCurrentUser()) {
            window.location = "/";
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        axios.post(helpers.getAPI(this.props.match.params.country.toUpperCase()) + '/device/shop/anonymous/register/' + this.props.match.params.guid, JSON.stringify(this.state)).then(res => {
            this.setState({loading: false})
            Swal.fire('Account created', '', 'success').then(() => {
                window.location = "/"+res.data.companyName;
            })
        }).catch(error => {
            this.setState({loading: false});
            Swal.fire({
                title: 'Something went wrong..',
                text: helpers.parseError(error),
                icon: 'error',
                confirmButtonColor: "#26B4FF"
            });
        })
    };

    componentDidMount() {
        axios.get(helpers.getAPI(this.props.match.params.country.toUpperCase()) + '/device/shop/anonymous/register/get/details/' + this.props.match.params.guid).then(res => {
            this.setState({
                loading: false,
                departments: this.props.match.params.department ? res.data.departments.filter(department => department.id === parseInt(this.props.match.params.department)) : res.data.departments,
                countryCodes: res.data.countryCodes,
                manager: res.data.manager,
                managerLogo: res.data.managerLogo,
                logo: res.data.logo,
            })
        }).catch(error => {
            this.setState({loading: false});
            Swal.fire({
                title: 'Something went wrong..',
                text: helpers.parseError(error),
                icon: 'error',
                confirmButtonColor: "#26B4FF"
            });
        })
    }

    render() {
        return (
            <>
                {helpers.getSystem() == "moduu" && <ShaderGradientCanvas
                    importedfiber={{ ...fiber, ...drei, ...reactSpring }}
                    style={{
                        position: 'fixed',
                        height: "100%"
                    }}
                >
                    <ShaderGradient

                        control={"query"}
                        urlString={"https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=0.6&cAzimuthAngle=0&cDistance=3.1&cPolarAngle=90&cameraZoom=1&color1=%233a3a38&color2=%2359bfde&color3=%2300394e&destination=onCanvas&embedMode=off&envPreset=city&format=gif&fov=30&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=2&positionX=0&positionY=-2.1&positionZ=-0.8&range=disabled&rangeEnd=31.4&rangeStart=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=90&shader=defaults&toggleAxis=false&type=waterPlane&uAmplitude=0&uDensity=1.1&uFrequency=5.5&uSpeed=0.2&uStrength=2&uTime=0&wireframe=false"
                        }/>
                </ShaderGradientCanvas>}
            <div className="authentication-wrapper authentication-1 px-4">
                {this.state.loading && <Loader/>}

                {!AuthService.getCurrentUser() && <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '20%' }}>
                                <img className={"mb-2"} style={{width: '250px'}} src={this.state.managerLogo ? this.state.managerLogo : (process.env.PUBLIC_URL + '/img/mentech-hvidblaa.svg')}/>
                                <br/>
                                {helpers.getSystem() == "Mentech" && <small as={"h6"} style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>SHOP</strong></small>}

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-4">
                            <Card>
                                {this.state.logo && <Card.Header>
                                    <img className={"mb-2"} style={{maxHeight: '35px'}} src={this.state.logo}/>
                                </Card.Header>}
                                <Card.Body>
                                    <form onSubmit={this.handleSubmit}>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Full name")}</Form.Label>
                                            <Form.Control name="fullName" value={this.state.fullName || ""} required onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Address")}</Form.Label>
                                            <Form.Control name="address" value={this.state.address || ""} required onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>{this.props.t("Zip postal")}</Form.Label>
                                                    <Form.Control name="zipPostal" value={this.state.zipPostal || ""} required onChange={this.handleInputChange}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group>
                                                    <Form.Label>{this.props.t("City")}</Form.Label>
                                                    <Form.Control name="city" value={this.state.city || ""} required onChange={this.handleInputChange}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group>
                                            <Form.Label>{this.props.t('Department')}</Form.Label>
                                            <Form.Control as={"select"} name={"departmentId"} value={this.state.departmentId || ""} onChange={this.handleInputChange} required>
                                                <option value={""}>{this.props.t('Choose an option')}</option>
                                                {this.state.departments && this.state.departments.map(department => (
                                                    <option key={department.id} value={department.id}>{department.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Employee ID")} / {this.props.t("Reference")}</Form.Label>
                                            <Form.Control name="reference" value={this.state.reference || ""} onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Email")}</Form.Label>
                                            <Form.Control type={"email"} name="email" value={this.state.email || ""} required onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Row>
                                            <Col md={4}>
                                                <Form.Group>
                                                    <Form.Label>{this.props.t("Country Code")}</Form.Label>
                                                    <Form.Control as={"select"} name={"phoneCountryCode"} value={this.state.phoneCountryCode || ""} onChange={this.handleInputChange} required>
                                                        <option value={""}>{this.props.t('Choose an option')}</option>
                                                        {this.state.countryCodes && this.state.countryCodes.map(code => (
                                                            <option key={code.id} value={code.id}>{code.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Group>
                                                    <Form.Label>{this.props.t("Phone")}</Form.Label>
                                                    <Form.Control name="phone" value={this.state.phone} required onChange={this.handleInputChange}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Card bg={"dark"} className={"mb-4"}>
                                            <Card.Body className={"text-white"}>
                                                <h6><i className={"fa fa-info-circle"}></i> {this.props.t('Create your account')}</h6>
                                                <small className={"text-muted"}>Angiv dit ønskede brugernavn og adgangskode som du skal benytte til at logge på shoppen.</small>
                                            </Card.Body>
                                        </Card>
                                        <Form.Group>
                                            <Form.Label>{this.props.t("Username")}</Form.Label>
                                            <Form.Control name="username" value={this.state.username} required onChange={this.handleInputChange}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>{this.props.t("Password")}</div>
                                            </Form.Label>
                                            <Form.Control name="password" type="password" value={this.state.password} required onChange={this.handleInputChange}/>
                                        </Form.Group>

                                        <div className="d-flex justify-content-between align-items-center m-0">
                                            <Button type={"submit"} variant="primary">{this.props.t("Sign up")}</Button>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    {this.state.managerLogo && <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                {helpers.getSystem() == "Mentech" && <small as={"h6"} style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>POWERED BY</strong></small>}
                                <br/>
                                <img style={{width: '200px'}} src={process.env.PUBLIC_URL +helpers.getLogo()}/>
                            </div>
                        </div>
                    </div>}
                </div>}
            </div>
            </>
        )
    }
}

export default withTranslation()(Register);
