import './polyfills'
import React from 'react'
import Popper from 'popper.js'
import App from './App'
import * as serviceWorker from './serviceWorker'
import i18n from "./i18n";
import ReactDOM from "react-dom/client"
import { Provider } from 'react-redux'
import store from './store'
import {I18nextProvider} from "react-i18next";
import helpers from "./shared/layouts/helpers";
import {useParams} from "react-router-dom";
import {titleTemplate} from "./routes";

const intlConfig = {
    locale: (helpers.getUser() && helpers.getUser().connectTo == "US") ? "en-US" : "da-DK",
    options: {
        style: 'currency',
        currency: (helpers.getUser() && helpers.getUser().connectTo == "US") ? "USD" : "DKK",
        maximumFractionDigits: 0
    }
}

const intl = new Intl.NumberFormat(intlConfig.locale, intlConfig.options);

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
          <I18nextProvider i18n={i18n}>
              <App currency={intl}/>
          </I18nextProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
