import React, { Component } from 'react'
import { connect } from 'react-redux'
import helpers from "./helpers";
import {Col, Row} from "react-bootstrap";

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`}>
          <div className="container-fluid d-flex flex-wrap text-center container-p-x pb-3">

              {helpers.getUser().managerLogo && <div className="mt-2 text-left">
                  <br/>
                  <img src={process.env.PUBLIC_URL + helpers.getLogo(true)} style={{width: '100px'}}/>
              </div>}

              {helpers.getUser().managerLogo &&<div className={"mx-3  mt-3 "} style={{borderRight: '1px solid #f7f7f7'}}></div>}

              <div className="pt-3 text-left" >
                  <small className={"text-muted"}>
                      <span className="footer-text font-weight-bold "><i className={"fas fa-copyright"}/> {new Date().getFullYear()} Cloud Captain ApS. All rights reserved</span>
                      <br/>
                      <span className="footer-text  font-weight-bold text-left">CVR: 37534374</span>
                      <span className="footer-text font-weight-bold text-left ml-2"><i className={"ion ion-ios-mail"}/> {helpers.getUser().managerEmail ? helpers.getUser().managerEmail : "erhverv@mentech.dk"}</span>
                      <span className="footer-text  font-weight-bold text-left ml-2"><i className={"ion ion-ios-call"}/> {helpers.getUser().managerPhone ? helpers.getUser().managerPhone : "70 666 903"}</span>
                  </small>
              </div>

          </div>
      </nav>
    )
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter)
