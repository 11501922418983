import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout2 from "./shared/layouts/LayoutWithoutSidenav";
import helpers from "./shared/layouts/helpers";

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });
const system = helpers.getSystem();
// ---
// Default application layout

export const DefaultLayout = Layout2;

// ---
// Document title template

export const titleTemplate = '%s | ' + system;

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/';
export const routes = [

  {
    path: '/:company?',
    component: lazy(() => import('./components/Dashboard'))
  }, {
    path: '/profile/:tab?',
    component: lazy(() => import('./components/Profile'))
  }, {
    path: '/assortment/:id',
    component: lazy(() => import('./components/DeviceShopProducts'))
  }, {
    path: '/order/:id',
    component: lazy(() => import('./components/DeviceShopOrder'))
  }, {
    path: '/login',
    component: lazy(() => import('./components/Login'))
  }, {
    path: '/verifyLogin/:token/:country',
    component: lazy(() => import('./components/VerifyLogin'))
  }, {
    path: '/register/:guid/:country/:department?',
    component: lazy(() => import('./components/Register'))
  }, {
    path: '/claim/:guid',
    component: lazy(() => import('./components/Claim'))
  }, {
    path: '/claim/print/:guid',
    component: lazy(() => import('./components/ClaimInvoicePrint'))
  },
];
