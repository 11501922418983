import React, {Component, useState} from 'react'
import {Modal, Badge, Button, Card, Form, Nav, Tab, Tabs, Row, Col, InputGroup} from 'react-bootstrap'
import parse from 'html-react-parser';
import "../../index.css"

export default class TextModal extends Component {
    constructor(props) {
        super(props);
    }


    handleClose = () => (
        this.props.onClose()
    );

    render(){
        if(!this.props.show){
            return null;
        }

        return (
            <>
                <Modal show={true} onHide={this.handleClose} backdrop="static" size={this.props.size ? this.props.size : 'md'}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.title}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{whiteSpace: 'pre-wrap'}} className={"contractAppendum"}>
                            {parse(this.props.text)}
                        </Modal.Body>

                        <Modal.Footer>

                            {this.props.markRead && <Button variant="primary" onClick={(e) => this.props.markRead(e)}>{this.props.t('Mark read')}</Button>}
                            <Button variant="secondary" onClick={this.handleClose}>{this.props.t("Close")}</Button>
                        </Modal.Footer>
                </Modal>
            </>
        )
    }
}
