import React, {Component, useContext} from 'react'
import Router from './shared/Router'
import './vendor/styles/bootstrap.scss'
import './vendor/styles/appwork.scss'
import './vendor/styles/theme-corporate.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './App.scss'
import './index.css'
import axios from "axios";
import Login from "./components/Login";
import AuthService from "./services/auth-service";
import AnonymousRouter from "./shared/AnonymousRouter";
import ErrorBoundary from "./shared/ErrorBoundary";
import CacheBuster from "react-cache-buster";
import packageInfo from "./../package.json";
import Loader from "./shared/Loader";
const isProduction = process.env.NODE_ENV === 'production';
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return AuthService.getCurrentUser() ? <CacheBuster currentVersion={packageInfo.version} isEnabled={isProduction} isVerboseMode={false} loadingComponent={<Loader/>}><ErrorBoundary><Router {...this.props} /></ErrorBoundary></CacheBuster>: <AnonymousRouter/>;
  }
}

axios.interceptors.request.use(function (config) {
  if (AuthService.getCurrentUser() && !AuthService.getCurrentUser().switching && AuthService.getCurrentUser().accessToken) {
    config.headers.common["X-Authorization"] = AuthService.getCurrentUser().accessToken;

  }
  return config;
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (AuthService.getCurrentUser() && !AuthService.getCurrentUser().switching && (error.response && error.response.status === 403) ) {
    return AuthService.refreshToken().then(() => {
      error.config.headers["X-Authorization"] = AuthService.getCurrentUser().accessToken;
      return axios.request(error.config);
    }).catch(error => {
      AuthService.logout();
    });
  }

  if (AuthService.getCurrentUser() && error.response && error.response.status === 404) {
    window.location = "/404";
  }

  return Promise.reject(error);
});

export default App
