import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';
import {withTranslation} from "react-i18next";
import {ShaderGradientCanvas, ShaderGradient, useThree} from 'shadergradient'
import * as reactSpring from '@react-spring/three'
import * as drei from '@react-three/drei'
import * as fiber from '@react-three/fiber'
import "../index.css"
import {titleTemplate} from "../routes";

class Login extends Component {
    constructor(props) {
        super(props);
        document.title = titleTemplate.replace('%s', this.props.t("Login"))
        document.body.style.background = this.props.match.params.company == "humac" ? "#000000" : "linear-gradient(to right, #243449, #52768f)"; //"#243449"

        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');
        this.state = {
            username: email ? email : "",
            password: "",
            rememberMe: "",
            country: "",
            managerLogo: "",
            logo: "",
            loading: this.props.match.params.company ? true : false,
            error: false,
        };


        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true, error: false});

        if (this.state.forgotPassword) {
            axios.post(helpers.getAPI(this.state.country) + '/device/shop/anonymous/register/reset/password', JSON.stringify(this.state)).then(res => {
                this.setState({loading: false})
                Swal.fire(this.props.t('Success'), this.props.t('If you have an account with us, your new password is sent to your email.'), 'success').then(() => {
                    this.setState({forgotPassword: !this.state.forgotPassword})
                })
            }).catch(error => {
                this.setState({loading: false});
                Swal.fire(
                    'Something went wrong..',
                    helpers.parseError(error),
                    'error'
                );
            })

            return null;
        }

        AuthService.login(this.state.username, this.state.password, this.state.country).then(
            () => {
                window.location = "/";
            },
            error => {
                Swal.fire(
                    this.props.t("Invalid credentials!"),
                    this.props.t("Please check your username and password."),
                    'error',
                );

                this.setState({
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            if (helpers.isValidCountry(data.country_code)) {
                this.setState({country: data.country_code});
                this.getManagerDetails(data.country_code);
            } else {
                this.getManagerDetails("DEV");
            }
        }).catch(error => {
            this.getManagerDetails("DEV");
        });
    }

    getManagerDetails(connectTo) {
        if (!this.props.match.params.company || this.props.match.params.company == "azure") {
            this.setState({loading: false}, () => {return false;});
        } else {
            axios.get(helpers.getAPI(connectTo) + '/device/shop/anonymous/manager/get/details/'+this.props.match.params.company).then( (response) => {
                this.setState({
                    loading: false,
                    manager: response.data.manager,
                    managerLogo: response.data.managerLogo,
                    logo: response.data.logo,
                });
            })
        }
    }

    render() {
        return (

            <div className="authentication-wrapper authentication-1 "  style={helpers.getSystem() != "moduu" ? {background: (this.props.match.params.company == "humac" ? "#000000" : "linear-gradient(to right, #243449, #52768f)")} : {}}>
                {this.state.loading && <Loader/>}
                {helpers.getSystem() == "moduu" && <ShaderGradientCanvas
                    importedfiber={{ ...fiber, ...drei, ...reactSpring }}
                    style={{
                        position: 'fixed',
                        top: 0,
                    }}
                >
                    <ShaderGradient

                        control={"query"}
                        urlString={"https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=0.6&cAzimuthAngle=0&cDistance=3.1&cPolarAngle=90&cameraZoom=1&color1=%233a3a38&color2=%2359bfde&color3=%2300394e&destination=onCanvas&embedMode=off&envPreset=city&format=gif&fov=30&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=2&positionX=0&positionY=-2.1&positionZ=-0.8&range=disabled&rangeEnd=31.4&rangeStart=0&reflection=0.1&rotationX=0&rotationY=0&rotationZ=90&shader=defaults&toggleAxis=false&type=waterPlane&uAmplitude=0&uDensity=1.1&uFrequency=5.5&uSpeed=0.2&uStrength=2&uTime=0&wireframe=false"
                        }/>
                </ShaderGradientCanvas>}

                <div className="authentication-inner py-5">
                    <div className="d-flex justify-content-center align-items-center">
                         <div className="col-md-4 my-4">
                             <div className="w-250 position-relative text-center" style={{ paddingBottom: '20%' }}>
                                 <img className={"mb-2"} style={{width: (this.props.match.params.company == "humac" ? '100%' : '250px')}} src={this.state.managerLogo ? this.state.managerLogo : (process.env.PUBLIC_URL + helpers.getLogo())}/>
                                 <br/>
                                 {helpers.getSystem() == "Mentech" && <small as={"h6"}  style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>SHOP</strong></small>}
                             </div>
                            {!this.state.forgotPassword && <Card>
                                <Card.Body>
                                    <form onSubmit={this.handleSubmit} >
                                        {this.props.match.params.company != "azure" && <Form.Group>
                                            <Form.Label>{this.props.t("Username")}</Form.Label>
                                            <Form.Control name="username" value={this.state.username} onChange={this.handleInputChange}/>
                                        </Form.Group>}
                                        {this.props.match.params.company != "azure" && <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>{this.props.t("Password")}</div>
                                            </Form.Label>
                                            <Form.Control name="password" type="password" value={this.state.password} onChange={this.handleInputChange}/>
                                        </Form.Group>}
                                        <Form.Group>
                                            <Form.Label className="d-flex justify-content-between align-items-end">
                                                <div>{this.props.t("Connect to")}</div>
                                            </Form.Label>
                                            <Form.Control required as={"select"} name={"country"} value={this.state.country || ""} onChange={this.handleInputChange} defaultValue={""}>
                                                <option disabled value={""}>{this.props.t("Please select an option")}</option>
                                                {JSON.parse(process.env.REACT_APP_API_LIST).map(item => (
                                                    <option key={item.code} value={item.code}>{item.country}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <div className="d-flex justify-content-between align-items-center m-0">
                                            {this.props.match.params.company != "azure" && <div>
                                                <Form.Check type="checkbox" name="rememberMe" custom checked={this.state.rememberMe} onChange={this.handleInputChange} label={this.props.t("Remember me")} className="m-0 " id="login-remember-me"/>
                                                <a href={"#"} onClick={() => {this.setState({forgotPassword: !this.state.forgotPassword})}} className={"d-block small mt-2"}>{this.props.t('Forgot password?')}</a>
                                            </div>}
                                            {this.props.match.params.company != "azure" && <Button type={"submit"} variant="primary">{this.props.t("Sign In")}</Button>}
                                        </div>
                                    </form>


                                    {this.state.country &&  <div className={"text-center"}>
                                        <hr/>

                                        <a href={helpers.getAPI(this.state.country) + '/sso/login?sub=shop'} className={"btn btn-windows btn-block mt-4"}><i className={"fab fa-windows"}/> {this.props.t('Sign in with Microsoft Authenticator')}</a>
                                    </div>}

                                </Card.Body>
                            </Card>}

                             {this.state.forgotPassword && <Card>
                                 <form onSubmit={this.handleSubmit} >
                                     {this.state.logo && <Card.Header>
                                         <img className={"mb-2"} style={{maxHeight: '35px'}} src={this.state.logo}/>
                                     </Card.Header>}
                                     <Card.Body>
                                         <h5 className="text-center text-muted font-weight-normal mb-4">{this.props.t('Reset your password')}</h5>
                                         <p className={"text-center text-big text-muted"}>
                                             {this.props.t('Enter your email address and we will send you a new password')}
                                         </p>
                                         <hr className="mt-0 mb-3" />

                                         <Form.Group>
                                             <Form.Label>{this.props.t('Email')}</Form.Label>
                                             <Form.Control placeholder={this.props.t('Enter your email address')} name={"email"} value={this.state.email || ""} onChange={this.handleInputChange} />
                                         </Form.Group>

                                         <Form.Group>
                                             <Form.Label>{this.props.t('Connect to')}</Form.Label>
                                             <Form.Control required as={"select"} name={"country"} value={this.state.country || ""} onChange={this.handleInputChange} defaultValue={""}>
                                                 <option disabled value={""}>{this.props.t("Please select an option")}</option>
                                                 {JSON.parse(process.env.REACT_APP_API_LIST).map(item => (
                                                     <option key={item.code} value={item.code}>{item.country}</option>
                                                 ))}
                                             </Form.Control>

                                         </Form.Group>

                                         <a href={"#"} onClick={() => {this.setState({forgotPassword: !this.state.forgotPassword})}} className={"d-block small mt-2 mb-4"}>{this.props.t('Go back to login')}</a>


                                         <Button variant="primary" type={"submit"} block>{this.props.t('Send password reset email')}</Button>
                                     </Card.Body>
                                 </form>
                             </Card>}

                        </div>

                    </div>

                    {this.state.managerLogo && <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="ui-w-250">
                            <div className="w-250 position-relative text-center" style={{ paddingBottom: '40%' }}>
                                {helpers.getSystem() == "Mentech" && <small as={"h6"} style={{ letterSpacing: '0.15em' }} className={"text-white mt-4"}><strong>POWERED BY</strong></small>}
                                <br/>
                                <img style={{width: '200px'}} src={process.env.PUBLIC_URL + helpers.getLogo()}/>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        )
    }
}

export default withTranslation()(Login);
