import React, { Component } from 'react'
import {Form, Button, Row, Col, Card} from 'react-bootstrap'
import axios from "axios";
import Loader from "../shared/Loader";
import helpers from "../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from '../services/auth-service';
import {withTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {titleTemplate} from "../routes";

class ClaimGUID extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false}
        document.title = titleTemplate.replace('%s', this.props.t("Claim"))

    }


    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        axios.get(helpers.getAPI(params.get("ref") ? params.get("ref") : "DK") + '/device/shop/anonymous/claim/'+this.props.match.params.guid).then( (response) => {
            if (response.data) {
                localStorage.setItem("mentechShopDetails", JSON.stringify(response.data));
                window.location.reload();
            } else {
                window.location = "/";
            }
        });
    }


    render() {
        return (
            <div>
                {this.state.loading && <Loader/>}
            </div>
        )
    }
}

export default withTranslation()(ClaimGUID);